.form-builder-preview {
  display: flex;
  flex-direction: column;
  background: white;
  max-height: calc(100vh - 60px);
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  transform-origin: 0px 0px;
  overflow: scroll;
}
body {
  overflow: hidden;
}

.document-img {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.form-builder-preview.print-view {
  .preview-item > :not(:active) {
    border: none !important;
  }
  .signature-clear-btn {
    display: none !important;
  }
  .preview-item.focused {
    > * {
      border: none !important;
    }
  }

  &:not(.focused) {
    > {
      border: none !important;
    }
  }
  input {
    border: none !important;
    &:focus,
    &:active {
      outline: none !important;
    }
  }
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
}

@media print {
  .preview-item.focused {
    > * {
      border: none !important;
    }
  }

  &:not(.focused) {
    > {
      border: none !important;
    }
  }
  input {
    border: none !important;
    &:focus {
      outline: none !important;
    }
  }
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
}
