$focusedBorder: 2px solid #26bbad !important;
$nonFocusedBorder: 1px dashed #ddd;

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  font-size: 1.15em;
  background-color: #282c34;
}

.preview-item {
  // padding-top: 35px;
  // position: fixed;
  cursor: pointer;
  z-index: 1000;
}

.preview-item.focused {
  > * {
    animation: focusAnimation 1s linear;
    &:not(.no-box) {
      outline: $focusedBorder;
      box-shadow: 0px 15px 10px -15px #999;
      border-radius: 2px;
    }
    input[type="checkbox"] {
      border: none !important;
    }
  }
  &:not(.focused) {
    > :not(.no-box) {
      outline: $nonFocusedBorder;
      border-radius: 2px;
    }
  }
}

.preview-item > *:not(:active) {
  &:not(.no-box) {
    outline: $nonFocusedBorder;
    border-radius: 2px;
  }
}

@media print {
  .preview-item.focused {
    > * {
      border: none !important;
    }
  }

  &:not(.focused) {
    > {
      border: none !important;
    }
  }
  input {
    border: none !important;
    &:focus {
      outline: none !important;
    }
  }
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
    width: auto !important;
    overflow: scroll !important;
  }
  .form-builder-preview {
    overflow: unset !important;
  }

  .preview-item.focused {
    > * {
      animation: focusAnimation 1s linear;
      &:not(.no-box) {
        outline: none !important;
        box-shadow: none !important;
        border-radius: 0px;
      }
      input[type="checkbox"] {
        border: none !important;
      }
    }
    &:not(.focused) {
      > :not(.no-box) {
        outline: none !important;
        border-radius: 0px;
      }
    }
  }

  .preview-item > *:not(:active) {
    &:not(.no-box) {
      outline: none !important;
      border-radius: 0px;
    }
  }
}

@keyframes focusAnimation {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-3px);
  }
}

.layout-item {
  display: flex;
  align-items: center;
  position: absolute;
  padding-top: 35px;
  > * {
    outline: 1px dashed #aaa;
  }
  > * {
    &:active {
      outline: 2px dashed #888;
    }

    &:not(:active) {
      outline: 1px dashed #aaa;
    }
  }

  z-index: 2;
}

.element-controls {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 35;
}
.handle {
  cursor: move;
  &:focus,
  &:active {
    cursor: move;
  }
}

.form-controls {
  display: flex;
  flex-direction: column;
  width: 250px;

  .form-control {
    margin: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .draggable {
    background: white;
    color: black;
  }
}

.form-layout {
  flex: 3;
  min-height: 100vh;
  position: relative;
  background-color: white;
  overflow: scroll;
}
.edit-element-container {
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 10px;
  }
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
