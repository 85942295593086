.button {
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.38571429em 0.78571429em;
  &.small {
    padding: 0.48571429em 1em 0.48571429em;
  }
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  will-change: "";
  -webkit-tap-highlight-color: transparent;
  &:hover {
    // background-color: #c0c0c0;
    background-image: none;
    box-shadow: 0 0 0 1px transparent inset,
      0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    color: rgba(0, 0, 0, 0.8);
  }
  &:active {
    background-color: #babbbc;
    color: rgba(0, 0, 0, 0.9);
  }
  // &:focus {
  //   background-color: #cacbcd;
  //   color: rgba(0, 0, 0, 0.8);
  // }
}
.button.primary {
  background-color: #2185d0;
  color: #fff;
  text-shadow: none;
  background-image: none;
  &:hover {
    background-color: #1678c2;
    color: #fff;
    text-shadow: none;
  }
  &:focus {
    background-color: #0d71bb;
    color: #fff;
    text-shadow: none;
  }
  &:active {
    background-color: #1a69a4;
    color: #fff;
    text-shadow: none;
  }
}

.button.danger {
  background-color: #db2828;
  color: #fff;
  text-shadow: none;
  background-image: none;
  &:hover {
    background-color: #d01919;
    color: #fff;
    text-shadow: none;
  }
  &:focus {
    background-color: #ca1010;
    color: #fff;
    text-shadow: none;
  }
  &:active {
    background-color: #b21e1e;
    color: #fff;
    text-shadow: none;
  }
}
