.label-element {
  background: none;
  .label-input {
    &:focus,
    &:active {
      outline: none;
    }
    background: none;
  }
  .label-input-shadow {
    position: absolute;
    visibility: hidden;
  }
}
