.signature-element,
.initials-element {
  .signature-input,
  .initials-input {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    &.underlined {
      border-bottom: 1px solid #333;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 auto;
      height: 100%;
      width: 100%;
    }
    .button.primary {
      padding: 0.05em 1.05em 0.05em;
    }
  }

  .signed-container {
    position: relative;
    .signature-clear-btn {
      position: absolute;
      top: -20px;
      right: 0;
      margin: 0 auto;
      z-index: 10;
      padding: 5px 10px;
      &.hidden {
        visibility: hidden;
      }
      &.visible {
        visibility: visible;
      }
    }
  }
}
