// .form-builder-preview {
//   height: 100vh;
//   width: 100vw;
//   overflow: scroll;
//   margin-top: 60px !important;
//   margin-bottom: 60px !important;
// }
//
// @media print {
//   .form-builder-preview {
//     margin: 0;
//     transform-origin: 0px 0px;
//     overflow: unset;
//     position: absolute;
//   }
// }
//
.preview-controls {
  position: fixed;
  top: 25px;
  right: 15px;
  z-index: 11;
  border: 1px dashed #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.document-img {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}
