.field {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
  .field-label {
    font-size: 18px;
    color: #222;
    padding-right: 5px;
  }
}
