.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  &.fullscreen {
    width: 100vw;
    height: 100vh;
    .modal {
      width: 100vw;
      height: 100vh;
      margin: 0;
    }
  }
  .modal {
    z-index: 100;
    background: #dfdfdf;
    position: relative;
    border-radius: 3px;
    padding: 2rem;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  &:not(.fullscreen) {
    .modal {
      margin: 1.75rem auto;
      max-width: 600px;
    }
  }
  .modal-header {
    display: flex;
    justify-content: flex-end;
  }

  .modal-close-button {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: 0.3;
    cursor: pointer;
    border: none;
  }

  button {
    font-size: 0.9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: 0.3rem 1rem;
    margin-left: 0.5rem;
  }

  .button-default {
    background: #247ba0;
    color: #fff;
  }
}
