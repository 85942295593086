.print-form-builder-preview {
  display: relative;
  height: 100%;

  .preview-item {
    position: unset !important;
  }
}

.form-builder-preview.print-view {
  max-height: 100%;
  overflow: unset !important;
}

.preview-item.print-view {
  position: unset !important;
}

.preview-item {
  .signature-input {
    position: absolute;
    top: 0px;
  }

  .input {
    min-height: unset;
    padding: unset;
  }
}

.signed-doc-print-container {
  height: 100vh;
  overflow: auto;
}

@media print {
  body {
    // overflow: scroll;
    width: auto !important;
  }

  @page {
    margin: 0;
  }

  body {
    margin: 1.6cm;
  }

  .signed-doc-print-container {
    height: 100vh;
    overflow: unset !important;
  }

  .form-builder-preview.print-view {
    page-break-after: always;
  }
}
