.patient-form-controls {
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  left: 0;
  width: 100%;
  min-width: 100vw;
  height: 60px;
  // background: #282c34;
  background: #282c34df; //rgba(0, 0, 0, 0.95);
  // background: #ccc;
  z-index: 10;
  .button {
    margin: 0px 10px;
  }
  &.next-prev-controls {
    bottom: 0;
    // background: none;
    // background: rgba(0, 0, 0, 1);
    display: flex;
    justify-content: flex-start;
    // margin: 0 5px;
    // padding: 5px;
  }
  &.zoom-controls {
    display: flex;
    top: 0;
    // background: none;
    // background: rgba(0, 0, 0, 1);
    justify-content: flex-start;
    .button {
      margin: 0px 5px;
    }
  }

  .form-requirement {
    justify-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1;
    color: #555;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    &.error {
      color: red;
    }
  }
  .form-progress {
    // justify-self: flex-end;
    padding: 10px;
    color: #ddd;
    font-size: 20px;
  }
  .form-submit-btn {
    display: flex;
    display: flex;
    justify-self: flex-end;
    padding: 10px;
    margin-right: 30px;
  }
  .button.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-requirement {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: auto;
    padding: 3px;
    background: #282c34df; //rgba(0, 0, 0, 0.95);
    // border-radius: 3px;
    // margin-left: 10px;
    // margin-right: 10px;
  }
}
