.input {
  display: inline-flex;
  margin: 0;
  max-width: 100%;
  min-height: 20px;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 14px;
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
}

input.default {
  border: none;
  border-radius: 0;
  padding-left: 5px;
  padding-right: 5px;

  &:focus {
    outline: none;
  }
  .input {
    border: 0;
    padding: 10px 0;
  }
  .input ~ .border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    // height: 2px;
    background-color: #27ad8a;
  }
  .input:focus ~ .border {
    width: 100%;
    transition: 0.5s;
  }
}
