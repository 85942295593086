.form-layout {
  position: relative;
}
.form-layout-img {
  border: 1px dashed #ccc;
  &.active {
    border: 3px dashed #ccc;
  }
  -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
  -webkit-user-select: none;
  -ms-user-select: none; /* From IE10 only */
  user-select: none; /* Not valid CSS yet, as of July 2012 */
  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
}
