.radio-group {
  height: 100%;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  .radio-group-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
  }
  .radio-item-label {
    padding-left: 10px;
  }
}
