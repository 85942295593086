.date-element {
  .date-input {
    border: none;
    width: 150px;
    border-radius: 0;
    border-bottom: 1px solid #222;
  }
  &:focus {
    outline: none;
  }
}
