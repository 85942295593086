@import url("https://fonts.googleapis.com/css?family=Sofia&display=swap");
@import url("https://fonts.googleapis.com/css?family=Arizonia&display=swap");
@import url("https://fonts.googleapis.com/css?family=Allura&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

@media print {
  .no-print {
    display: none !important;
    visibility: hidden !important;
  }
}

@media print {
  body {
    overflow: scroll !important;
  }
  .preview-item.focused > * {
    border: none !important;
  }

  input {
    border: none !important;
  }
  input:focus {
    outline: none !important;
  }
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
}
