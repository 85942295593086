.checkbox-element {
  display: flex;
  .checkbox-input-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 5px;
  }
  .checkbox-input {
    margin-left: 10px;
    margin-right: 10px;
  }
  .checkbox-input-label {
    margin-right: 5px;
  }
}
.preview-item {
  .checkbox-element {
    margin-top: 5px;
  }
}
