.form-controls {
  color: white;
  .app-header {
    padding: 5px;
  }
}
.form-control {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  border-radius: 0.18571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.form-control:-moz-drag-over {
  color: red;
}

.form-control:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  color: pink;
}

.App.TEXT {
  .draggable.TEXT {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
.App.LABEL {
  .draggable.LABEL {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
.App.DATE {
  .draggable.DATE {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
.App.INITIALS {
  .draggable.INITIALS {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
.App.CHECKBOX {
  .draggable.CHECKBOX {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
.App.SIGNATURE {
  .draggable.SIGNATURE {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
.App.RADIO_GROUP {
  .draggable.RADIO_GROUP {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
.App.COACH_SIGNATURE {
  .draggable.COACH_SIGNATURE {
    color: #ccc;
    background: rgba(34, 36, 38, 0.15);
  }
}
